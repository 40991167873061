
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;1,200;1,300;1,700&family=IBM+Plex+Serif:wght@300;400&family=Kaushan+Script&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'IBM Plex Serif', serif;
  background-image: url(/public/buddha-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 1.5s ease;
}

.quotebox-container{
  display: grid;
  place-items: center;
  height: 100vh; /* This will fill the entire height of the viewport */
}

#quote-box{
  background-color: rgba(255, 250, 235, .8);
  width: auto; /* Width will be as wide as the content up to the max-width */
  height: auto; /* Height will be as tall as the content up to the max-height */
  max-width: 40rem;
  max-height: 27rem;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  transition: all 0.5s ease-in-out;
}


#text{
  font-size: clamp(1rem, 0.6216rem + 1.6145vw, 2rem);;
  margin-bottom: 1.5rem;
}

#author{
  font-family: 'Kaushan Script', sans-serif;
  font-weight: 400;
  font-style: italic;
  display: flex;
  justify-content: flex-end;
  font-size: clamp(1.125rem, 0.8409rem + 1.2121vw, 1.75rem);
  color: #1d0c3f;
}

#author::before {
  content: "—\00a0";
}


#buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

button{
  cursor: pointer;
  color: #fee7a6;
  padding: .9rem 1rem;
  font-size: .9rem;
  font-family: 'IBM Plex Serif', serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: rgba(110,44,107,1);
  border: none;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  transition: all ease-in-out .4s;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari, Opera */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

button:hover{
  background-color: rgba(209,0,116,1);
  transform: translatey(-5px);
  color: #fff;
}

.sticky-footer {
  margin-top: auto; /* Push the footer to the bottom */
  width: 100%;
  background-image: radial-gradient( circle farthest-corner at 1.4% 48.5%,  rgba(53,92,125,1) 13.7%, rgba(0,0,0,1) 51.7%, rgba(53,92,125,1) 83.4% );
  color: #fee7a6;
  text-align: center;
  padding: 1rem 0;
}

.sticky-footer a {
  color: #fee7a6;
  transition: all ease .3s;
  text-decoration: none;
}

.sticky-footer a:hover{
  color: #fc6a6a;

}

@media (max-width: 768px) {
  .quotebox-container{
    margin-top: 60px;
    align-items: flex-end;
  }
  #quote-box{
    margin: 40px 30px;
    align-self: flex-end;
  }
  body{
    background-position-x: 50%;
  }
  button{
    width: 100%;
    padding: .8rem .9rem;
    font-size: .8rem;
  }
  #text{
    color:black;
  }
}